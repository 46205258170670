<template>
  <div class="Inscripcion">
    <section class="relative top-layer">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div
              class="col-sm-12 bg-dark right"
              style="background-color:#963D5A"
            >
              <div class="pt64 pb32 p32 pb-xs-64">
                <h4 class="uppercase">{{diplomado.tipo_dip}} {{diplomado.nombre_dip}}</h4>
                <p>Para poderte registrar en el diplomado es necesario nos proporciones la siguiente información</p>
                <div
                  class="row"
                  style="color:white"
                >
                  <div class="col-sm-6">
                    <input
                      v-model="inscripcion.nombre_alu"
                      type="text"
                      name="name"
                      placeholder="Nombre"
                      class="transparent validate-required"
                    />
                  </div>
                  <div class="col-sm-6">
                    <input
                      v-model="inscripcion.apellidos_alu"
                      type="text"
                      name="name"
                      placeholder="Apellidos"
                      class="transparent validate-required"
                    />
                  </div>
                  <div class="col-sm-12">
                    <p>Con el teléfono y correo electrónico podrás acceder a ver el contenido del diplomado</p>
                  </div>
                  <div class="col-sm-6">
                    <input
                      v-model="inscripcion.telefono_alu"
                      type="tel"
                      name="name"
                      v-mask="'X#############'"
                      placeholder="Teléfono"
                      class="transparent validate-required"
                    />
                  </div>
                  <div class="col-sm-6">
                    <input
                      v-model="inscripcion.correo_alu"
                      type="email"
                      name="name"
                      placeholder="Correo electrónico"
                      class="transparent validate-required"
                    />
                  </div>
                  <div class="col-sm-4">
                    <input
                      v-model="inscripcion.pais_alu"
                      type="text"
                      name="pais"
                      placeholder="País"
                      class="transparent validate-required"
                    />

                  </div>
                  <div class="col-sm-4">
                    <input
                      v-model="inscripcion.estado_alu"
                      type="text"
                      name="estado"
                      placeholder="Estado"
                      class="transparent validate-required"
                    />
                  </div>
                  <div class="col-sm-4">
                    <input
                      v-model="inscripcion.municipio_alu"
                      type="text"
                      name="municipio"
                      placeholder="Municipio"
                      class="transparent validate-required"
                    />
                  </div>
                </div>
                <button
                  class="btn btn-filled btn-white"
                  v-text="'Inscribirme'"
                  @click="_doInscripcion"
                />
                <p :class="showRestaltar ? 'resaltar':''">Es necesario llenes todos los campos</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br><br><br><br><br>
    </section>
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
export default {
  name: "Inscripcion",
  mixins: [forms],
  data() {
    return {
      inscripcion: {
        nombre_alu: null,
        apellidos_alu: null,
        telefono_alu: null,
        correo_alu: null,
        pais_alu: null,
        estado_alu: null,
        municipio_alu: null,
      },
      errors: {
        nombre_alu: null,
        apellidos_alu: null,
        telefono_alu: null,
        correo_alu: null,
        pais_alu: null,
        estado_alu: null,
        municipio_alu: null,
      },
      diplomado: {
        contenido_web_dip: null,
        descripcion_dip: null,
        estatus_dip: null,
        id: null,
        id_dip: null,
        imagen_dip: null,
        nombre_dip: null,
        tipo_dip: null,
        url_dip: null,
      },
      showRestaltar: false,
    };
  },
  methods: {
    async _getDiplomado() {
      this.cargando = false;
      try {
        const { status, body } = await this.$http.get(
          "diplomado/" + this.$route.params.id
        );
        if ((status === 200 || status === 201) && "diplomado" in body) {
          if (body.diplomado.estatus_dip === "abierto") {
            this.diplomado = body.diplomado;
          } else {
            this.$router.push("/diplomados");
          }
        } else {
          this.$router.push("/diplomados");
        }
      } catch (err) {
        this.$router.push("/diplomados");
      }
      //   this.this._getThings("diplomado/" + this.$route.params.id, "diplomado");
    },
    _doInscripcion() {
      if (this._validateAll("inscripcion")) {
        this.showRestaltar = false;
        const data = {
          alumno: this.inscripcion,
          diplomado: this.diplomado.id_dip,
        };
        this._saveAll("alumno", data, "msg", "_doRedirect");
      } else {
        this.showRestaltar = true;
      }
    },
    _doRedirect(a) {
      console.log(a);
      this.$session.start();
      this.$session.set("diplomado", this.diplomado.nombre_dip);
      this.$router.push("/inscrito");
    },
  },
  created() {
    this._getDiplomado();
  },
};
</script>
<style lang="css">
.resaltar {
  background: white;
  color: red !important;
  font-weight: 600;
  padding: 16px 12px;
  margin-bottom: 24px;
}
</style>